export const usePeriodicJobStore = () => {
    const stateKey = 'usePeriodicJobState'
    const state = useState(stateKey, () => {
        return {
            jobs: [],
            timer_id: null,
        }
    })

    const jobRunner = () => {
        for (var job of state.value.jobs) {
            job.start()
        }
    }

    const startJobs = (jobs, interval) => {
        if (state.value.timer_id === null) {
            state.value.jobs = jobs
            state.value.timer_id = setInterval(jobRunner, interval)
        }
    }

    const appendJob = (job) => {
        state.value.jobs.push(job)
    }

    const stopJobs = () => {
        clearInterval(state.value.timer_id)
        for (var job of state.value.jobs) {
            if (job.end) {
                job.end()
            }
        }
        state.value.jobs = []
        state.value.timer_id = null
    }

    return {
        startJobs,
        appendJob,
        stopJobs,
    }
}